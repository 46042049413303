<template>
  <aside>
    <header>
      <h2>End Nomination Campaign</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main v-if="campaign">
      <p>Announce the winners for <strong>{{campaign.title}}</strong> nominations. View the results below:</p>

      <div v-if="'nominated_by' in campaign" class="nominated-by">
        <div class="nomination-award" v-for="({award, nominations}, index) in campaign.nominated_by" :key="`nomd_by_${index}`">
          <div class="award">
            <h4>{{award.name}}</h4>
          </div>
          <div class="results">
            <template v-if="nominations.length > 0">
              <div v-for="(nom_group, ngIndex) in nominations" :key="`nomgroup_${ngIndex}`">
                <user-card :user="nom_group.created_by" :height="24" />
                <div class="nom-results">
                  <ul class="cf-responses" v-if="campaign.custom_fields.length > 0">
                    <li v-for="[field_id, response] in Object.entries(nom_group.metadata.custom_field_responses)" :key="`cfr_${ngIndex}_${field_id}`">
                      <label>{{cfr_labels[field_id]}}</label>
                      <span>{{response}}</span>
                    </li>
                  </ul>
                  <hr>
                  <ul>
                    <li v-for="(u, nmIndex) in nom_group.for_users" :key="`nomgroup_${ngIndex}_li_${nmIndex}`">
                      <user-card :user="u" :height="16" />
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <span v-else>No nominations for this award.</span>
          </div>
        </div>
      </div>
      <loading-indicator v-else small />

      <hr>
      <div class="setting-row">
        <div class="title">
          <h4>Campaign winners</h4>
          <p>Select who should win the awards from this campaign.</p>
        </div>
        <div class="fields">
          <div v-if="'users_nominated' in campaign" class="nominations">
            <div class="nomination-award" v-for="(nomination, index) in campaign.users_nominated" :key="`nomres_${index}`">
              <div class="award">
                <h4>{{nomination.award.name}}</h4>
              </div>
              <user-picker v-model="nomination.users"></user-picker>
            </div>
          </div>
          <loading-indicator v-else small />
        </div>
      </div>
      <div class="setting-row">
        <div class="title">
          <h4>Custom message</h4>
          <p>Include a custom message with these awards.</p>
        </div>
        <div class="fields">
          <textarea rows="5" class="form-control" placeholder="(optional)" v-model="message"></textarea>
        </div>
      </div>
    </main>

    <footer>
      <button class="btn btn-sm btn-green" :class="{'loading': loading}" @click="endCampaign">End campaign</button>
    </footer>
  </aside>
</template>

<script>
import UserPicker from '@/components/UserPicker';

export default {
  props: ['data'],
  components: {
    UserPicker
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    cfr_labels() {
      return this.campaign.custom_fields.reduce((agg, val) => {
        agg[val.id] = val.label;
        return agg;
      }, {})
    }
  },
  data() {
    return {
      campaign: null,
      message: ''
    }
  },
  mounted() {
    this.campaign = _.cloneDeep(this.data)
    this.populateCampaign()
  },
  methods: {
    close() {
      this.$emit('done')
    },
    async populateCampaign() {
      const resp = await this.$api.Nominations.get_campaign(this.campaign.id)
      this.campaign = resp;
    },
    async endCampaign() {
      this.$store.commit('loading', true)
      const for_users = this.campaign.users_nominated.reduce( (obj, val) => {
        const users = _.map(val.users, 'id');
        const award = val.award;
        
        obj[award.id] = users;

        return obj;
      }, {})
      await this.$api.Nominations.end_campaign(this.campaign.id, for_users, this.message)
      this.$toast.success('Nomination campaign successfully ended.');
      this.$store.commit('loading', false)
      this.$emit('done', 'CAMPAIGN_ENDED');
    }
  },
}
</script>

<style lang="scss" scoped>
.nominated-by {
  h4 {
    font-size: 16px;
    font-weight: 700;
  }

  .results {
    .nom-results {
      border-radius: 4px;
      background: $light;
      position: relative;
      margin: 10px 0 0;
      &:before {
        content: '\f0d8';
        display: block;
        font-family: 'Font Awesome 5 Pro';
        font-size: 26px;
        font-weight: 700;
        color: $light;
        position: absolute;
        left: 5px;
        line-height: 10px;
        top: -5px;
      }

      hr {
        margin: 0;
      }
      > ul {
        padding: 15px;
        margin: 0 0;
        display: flex;
        flex-wrap: wrap;

        > li {
          display: block;
          padding: 0 10px;

          ::v-deep .user--card {
            .user--name {
              font-size: 12px;
            }
          }
        }

        &.cf-responses {
          
          > li {
            padding: 0 10px;
            font-size: 12px;
            > label {
              font-weight: 700;
              display: block;
              margin: 0;
              line-height: 12px;
            }
            > span {
              display: block;
            }
          }
        }
      }
    }
  }
}

.nominations {
  margin: 0 0;
  .nomination-award {
    background: #fff;
    box-shadow: rgba(0,0,0,0.05) 0 5px 10px;
    border: 1px solid rgba(0,0,0,0.05);
    border-radius: 10px;
    padding: 25px;

    +.nomination-award {
      margin-top: 25px;
    }

    .award {
      display: block;
      padding: 0 10px 0 0;

      > h4 {
        font-weight: 700;
        font-size: 16px;
        margin: 0 0 3px;
      }
    }

    .results {
      display: block; 
      flex: 1 0 0;
      padding: 0 15px 0 55px;
      min-width: 0;

      ::v-deep .user-picker {
        li {
          display: flex;
          align-items: center;
          > .nomination-count {
            display: block;
            background: darken($light, 10%);
            color: $muted-text;
            font-size: 10px;
            line-height: 1em;
            padding: 2px 5px;
            border-radius: 100em;
            font-weight:700;
            margin: 0 0 0 5px;
          }
        }
      }
    }
  }
}
</style>